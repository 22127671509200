.profile-container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  

}

.profile-image-card {
  width: 100%;
  height:100%;
  max-width: 350px;
  margin-bottom: 20px;
  position: relative;
}
/* .contact-details-card{
  width:500px;
  padding:10px 0px 10px 10px;
  margin-left: 20px;
  font-size: 25px;
} */
.contact-details-card {
  width: 100%;
  max-width: 500px;
}

.profile-header-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #0a4570; 
  /* background-image: url('/src/Assets/userimage.jpg'); */
}

/* .editt-button {
  background-color: #0a4570;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  transition: background-color 0.3s ease;
  display: center;
}

.editt-button:hover {
  background-color: #0056b3;
} */



.profile-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  text-align: center;
  width: 100%;
}

.profile-card-title {
  font-size: 24px;
  color: #0a4570;
  text-align: center;
  margin-bottom: 20px;
}

.profile-card-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-card-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.profile-card-label, .profile-card-value {
  flex: 1;
}

.profile-card-label {
  font-weight: bold;
  color: #0a4570;
  text-align: right;
  padding-right: 10px;
}

.profile-card-value {
  color: #777;
  text-align: left;
  padding-left: 10px;
}
/* for tablet */
@media (max-width: 768px) {
  .profile-container{
    margin-top: 30px;
  }
  .profile-image-card{
    width:250px;
    margin-left: 20px;
    /* margin-bottom: 0px; */
   }
  .contact-details-card{
    width:450px;
    /* padding:0px 0px 10px 10px; */
    margin-left: 20px;
    font-size: 16px;
  }
  .profile-image {
    width: 80px;
    height: 80px;
  }

  .edit-button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .profile-card {
    padding: 15px;
  }

  .profile-card-title {
    font-size: 23px;
  }
  span.profile-card-label{
    margin-left: 50px;
  }
  span.profile-card-value{
    margin-right: 50px;
    
  }

  .profile-card-details {
    align-items: center;
    
    }

  .profile-card-row {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .profile-card-label, .profile-card-value {
    text-align: left;
    padding: 0;
  }

  .profile-card-label {
    padding-bottom: 5px;
  }

  .profile-card-value {
    padding-top: 5px;
  }
}

@media (max-width: 480px) {
  .profile-image-card{
    width:150px;
    margin-left: 20px;
  }
  .contact-details-card{
    width:300px;
    padding:10px 0px 10px 10px;
    margin-left: 20px;
    font-size: 14px;
        
  }
  .profile-image {
    width: 60px;
    height: 60px;
  }

  .edit-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .profile-card {
    padding: 40px;
  }

  .profile-card-title {
    font-size: 20px;
    margin-right: 40px;
    padding-left: 25px;
    /* justify-content: space-between; */
  }
  span.profile-card-label{
    margin-left: 0px;
  }
  span.profile-card-value{
    margin-right: 0px;
    display: flex;
  flex-direction: row; 
  /* justify-content: center; */
    
  }
  div.profile-card-details{
    display: flex;
    flex-direction: column;
  }

  .profile-card-details {
    display: flex;
    flex-direction: row;
    align-items:center;
    /* display: flex; */
    /* justify-content: space-between; */
  }

  .profile-card-row {
    flex-direction: row;
    
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .profile-card-label, .profile-card-value {
    text-align: left;
    padding: 5px;
  }

  .profile-card-label {
    padding-bottom: 5px;
  }

  .profile-card-value {
    padding-top: 5px;
  }
}

/* Add this to your existing CSS file */

.editt-button {
  background-color: #ffff;
  border: 3px solid #0A4570;
  border-width:3.8px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  margin-top: 5px;
}

/* .editt-button:hover {
  background-color: #0A4570;
} */

.editt-button::before {
  content: '\270E'; /* Unicode for pencil icon */
  font-size: 16px;
  /* color: #333; */
}




