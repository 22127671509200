/* Sidebar.css */
.sidebarr {
    position: fixed;
    top: 0;
    left: -160px; /* Start off-screen */
    width: 150px;
    height: 100%;
    background-color: #0a4570;
    color: #fff;
    transition: left 0.3s  ease-in-out;
    z-index: 1000;
    overflow-y: auto;
}

.sidebarr.open {
    left: 0; /* Slide in when open */
}

.sidebarr-menu {
    list-style: none;
    padding: 20px;
    margin: 0;

}

.sidebarr-menu li {
    margin: 30px 20px;
    cursor: pointer;
    
}
.sidebarr-menu li:hover {
    margin: 30px 20px;
    cursor: pointer;
}


.sidebarr-menu a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

.close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

/* Show sidebar only on mobile screens */
