/* General Navigation Styling */

.custom-navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: #286197;
  position: fixed;
  top: 0;
  left: 60px; /* Adjust according to sidebar width */
  width: calc(100% - 60px); /* Adjust according to sidebar width */
  transition: left 0.3s ease, width 0.3s ease;
  z-index: 2;
}

.custom-navbar.sidebar-open {
  left: 180px; /* Adjust according to sidebar open width */
  width: calc(100% - 180px);
}

.container-fluid {
  display: flex;
  align-items: center;
  width: 100%;
}

.toggle-sidebar-btn {
  cursor: pointer;
  color: white;
  font-size: 20px;
  margin-right: auto;
  margin-left: 2px;
}

.dropdown {
  position: relative;
  margin-left: auto;
  font-size: 18px;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  display: none;
  position: absolute;
  right: 10px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 10px 15px;
  background-color: white;
  border: none;
  width: 150%;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  color: #040404;
}

.dropdown-item:hover {
  background-color: #b1cee3;
  color: white;
}

.navigation-container {
  display: flex;
  height: 100vh; /* Ensures the container takes full height */
}

.main-content {
  flex-grow: 1;
  padding: 1rem;
  height: 100vh; /* Ensures the content takes full height */
  overflow: auto;
  transition: margin-left 0.3s ease;
}

.main-content.sidebar-open {
  margin-left: 100px; /* Adjust according to sidebar open width */
}
.user-initials {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #0a4570;  /* or any other color you prefer */
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  margin-right: 10px;
}
/* Navigation.css */

.custom-navbar {
  display: flex;
  align-items: center;
  /* Add other styles */
}

.user-profile {
  display: flex;
  align-items: center;
}

.profile-image-navbar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}


/* Responsive Design */
@media (max-width: 992px) {
  .custom-navbar {
    justify-content: space-between;
  }

  .toggle-sidebar-btn {
    margin-left: 15px;
  }


  .dropdown-menu {
    right: 10px;
    left: 0;
  }

  .dropdown-toggle {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .custom-navbar {
    padding: 0.5rem;
    left: 45px; /* Adjust according to sidebar width */
    width: calc(100% - 45px); /* Adjust according to sidebar width */
  }

  .custom-navbar.sidebar-open {
    left: 150px; /* Adjust according to sidebar open width */
    width: calc(100% - 150px);
  }

  .toggle-sidebar-btn {
    font-size: 18px;
    margin-left: 1rem;
  }

  .dropdown {
    left: auto;
    right: 1rem;
    font-size: 18px;
  }

  .dropdown-toggle {
    font-size: 1.2rem;
  }

  .dropdown-menu {
    width: 150px;
  }
}

@media (max-width: 576px) {
  .custom-navbar {
    padding: 0.5rem;
    left: 40px; /* Adjust according to sidebar width */
    width: calc(100% - 40px); /* Adjust according to sidebar width */
  }

  .custom-navbar.sidebar-open {
    left: 135px; /* Adjust according to sidebar open width */
    width: calc(100% - 120px);
  }

  .toggle-sidebar-btn {
    font-size: 16px;
    margin-left: 2px;
  }

  .dropdown-toggle {
    font-size: 1rem;
  }

  .dropdown-menu {
    width: 150px;
  }
}

@media (max-width: 1200px) {
  .dropdown-menu {
    width: 150px;
    padding-right: 20px;
  }
  .menu-items{
    margin-left: 10px;
  }

  .dropdown-toggle {
    font-size: 1rem;
  }
  .toggle-sidebar-btn {
    font-size: 16px;
    margin-left: 10px;
  }
  .custom-navbar.sidebar-open {
    left: 130px; /* Adjust according to sidebar open width */
    width: calc(100% - 120px);
  }
}
