/* About.css */

.about-us-container {
  max-width: 100%;
  margin: 20px auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-header {
  margin-bottom: 20px;
  text-align: center;
}

.about-us-header h1 {
  font-size: 36px;
  font-weight: bold;
  color:#0a4570;
  text-shadow: 0 0 10px #0a4570(0, 0, 0, 0.1);
  position: relative;
}

.about-us-header h1::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #333;
  z-index: -1;
}

.team-photo {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: rotate(5deg);
}

.about-us-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.about-us-text {
  flex-basis: 40%;
  margin-right: 20px;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-us-text h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-us-text p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
}

.about-us-image {
  flex-basis: 50%;
}
.PlatformAbout,.MissionAbout{
  color: #0a4570;
}
.learning-experience {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: rotate(-5deg);
}

.platform-screenshot {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: rotate(5deg);
}

/* Add some animations to make it more engaging */
.about-us-container {
  transition: all 0.3s ease-in-out;
}

.about-us-container:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.about-us-text h2 {
  transition: all 0.3s ease-in-out;
}

.about-us-text h2:hover {
  color: #555;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.team-photo:hover {
  transform: rotate(0deg);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.learning-experience:hover {
  transform: rotate(0deg);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.platform-screenshot:hover {
  transform: rotate(0deg);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}