.dialog-box {
  border: 1px solid #ccc;
  padding: 20px;
  width: 500px;
  margin: 0 auto;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  margin-top: 150px;
}

.dialog-box h2 {
  margin-bottom: 20px;
  color: #0a4570;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.submit-button {
  max-width: 20%;
  display: block;
  margin: 0 auto; /* Center the submit button */
}

.submit-button button {
  padding: 5px 5px;
  background-color: #0a4570;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.alert {
  margin-top: 20px;
  padding: 10px;
  /* border: 1px solid #004a02; */
  /* background-color: #e3f5ff; */
  color: #000101;
}

/* Loader styles */
.loader {
  margin: 20px auto 0; /* Center horizontally */
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #0a4570;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

/* Loader animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
