
.edit-exam-form-container {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto; 
}

/* Header styles */
.edit-exam-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #0a4570;
}

/* Form group styles */
.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 1rem;
    background-color: #fff;
}

.form-group input:disabled,
.form-group textarea:disabled,
.form-group select:disabled {
    background-color: #e9e9e9;
}

/* Toggle switch styles */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: green;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

/* Submit button styles */
.submitt-button {
    display: block;
    width: 20%;
    padding: 0.6rem;
    background-color: #0a4570;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
    margin: 0 auto; /* Center the button */
}

.submitt-button:hover {
    background-color: #0a4570;
}

/* Loader styles */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #0a4570;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Responsive styles */

/* Large desktops and laptops */
@media (min-width: 1200px) {
    .edit-exam-form-container {
        max-width: 70%;
        padding: 30px;
    }

    .edit-exam-form-container h2 {
        font-size: 2.5rem;
    }

    .submitt-button {
        width: 15%;
    }
}

/* Tablets and small desktops */
@media (max-width: 1199px) and (min-width: 769px) {
    .edit-exam-form-container {
        max-width: 80%;
        padding: 25px;
    }

    .edit-exam-form-container h2 {
        font-size: 2rem;
    }

    .submitt-button {
        width: 18%;
    }
}

/* Mobile devices */
@media (max-width: 768px) {
    .edit-exam-form-container {
        max-width: 90%;
        padding: 1rem;
        margin-left: 5%;
        margin-right: 5%;
    }

    .edit-exam-form-container h2 {
        font-size: 1.5rem;
    }

    .form-group input,
    .form-group textarea,
    .form-group select {
        padding: 0.8rem;
        font-size: 0.9rem;
    }

    .submitt-button {
        width: 50%;
        padding: 0.8rem;
        font-size: 0.9rem;
    }
}

/* Add scroll bar for overflow content */
.form-group textarea {
    resize: vertical;
    max-height: 200px;
    overflow-y: auto;
}
