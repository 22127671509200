

.loader-overlay {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0a4570;
  border-radius: 50%;
  width: 40px;
  height: 70px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.exam-form {
  
  padding: 20px;
  max-width: 90%;
  margin-left: 15%;
  margin-right: 15%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  
}

.Examtitle {
  color: #0a4570;
  text-align: center;
}

.forme-group {
  margin-bottom: 10px;
}
.forme-group-question {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.forme-group-question input[type="text"] {
  flex: 1;
}

.forme-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.forme-group input[type="text"] {
  flex: 1;
}

.upload-button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #0a4570;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.forme-select,
.forme-control {
  width: 100%;
  padding: 7px;
  margin-top: 2px;
}
.button-group {
    display: flex;
    justify-content: space-between;
 }


.add-option-button,
.add-question-button,
.upload-button{
  padding: 10px 20px;
  background-color: #0a4570;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-questions-button {
  padding: 10px 20px;
  background-color: #0a4570;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
/* .upload-button{
  margin-left: 10px;
} */
.import-section {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f1f1f1;
}

.dropzone {
  padding: 20px;
  border: 2px dashed #0a4570;
  border-radius: 5px;
  background-color: #e9ecef;
  text-align: center;
  cursor: pointer;
}

.MCQcontainer,
.SUBcontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
}

.MCQFORM,
.SUBFORM {
  flex: 3;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px; 
  margin-bottom: 20px;
}

.import-section {
  flex: 1;
  max-width: 300px;
}

.import-section .dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
}
.IMPORTMCQ,
.IMPORTSUB,
.ADDMCQ
.ADDSUB{
  color-scheme: #0a4570;
}


.file-links {
  font-weight: bold;
  margin-top: 20px;
}

.file-links a {
  color: #007bff;
  text-decoration: underline;
}

.file-links a:hover {
  color: #0056b3;
}

@media (max-width: 768px) {

  .exam-form {
    margin-top: 5px;
    padding: 10px;
    margin-left: 15%;
    margin-bottom: 15%;
  }
  .mcq-section{

    overflow-y: scroll;
  }
  .MCQcontainer,
  .SUBcontainer {
    flex-direction: column;
    overflow-y: auto; 
  max-height: 50vh; 
  }
  
  .import-section {
    max-width: 100%;
    margin-top: 1px;
  }
  .button-group{
    padding-top: 5px;
    flex-direction: row;
  }
}