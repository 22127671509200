/* Background blur */
.addmorequestion-container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: -1;
}

.addmorequestion-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  z-index: 1001;
  width: 90%;
  max-width: 600px;
}

/* Close button styling */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #333; 
  /* z-index: 1002; */
}



/* Optional styling for the remove button in MCQ options */
.remove-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

