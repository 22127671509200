/* Main Form Styling */
.edit-exam {
    max-width: 800px;
    margin: 0 auto; /* Center horizontally */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 100px; /* Adjust as needed */
    margin-bottom: 40px; /* Adjust as needed */
}

.MCQFORM {
    display: flex;
    flex-direction: column;
}

.forme-group-questionn, .option-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.upload-questionimage, .upload-optionimage {
    display: flex;
    align-items: center;
    width: 35%;
    padding-right: 30px;

}

.upload-buttonn {
    margin-left: 30px;
    cursor: pointer;
    padding: 8px;
    background-color: #0a4570;
    color: #fff;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 14px;
    width: 70%; /* Set width to 100% for equal width */
    box-sizing: border-box; /* Include padding in the width calculation */
}

.add-optionn {
    cursor: pointer;
    padding: 8px;
    background-color: #0a4570;
    color: #fff;
    border-radius: 5px;
    margin-top: 5px; /* Adjust spacing */
    font-size: 14px;
    width: auto; /* Reset width */
}
.upload-buttonn:hover, .add-optionn:hover {
    background-color: #063b5a;
}

.form-control {
    flex: 1;
    width: 188px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 16px;
    /* Increase right padding for question text section */
    padding-right: 50px;
}

.image-preview-container {
    position: relative;
    margin-top: 10px;
}

.image-preview {
    max-width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 5px;
}

.remove-image {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
}

.remove-image:hover {
    background-color: rgba(0, 0, 0, 0.9);
}

.add-option-container {
    margin-top: 10px;
}

.remove-option {
    margin-top: 10px;
    margin-left: 5px;
    padding: 5px 10px;
    background-color: #ff0606d7;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.remove-option:hover {
    background-color: #d70f0f;
}

.form-group-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.btn {
    padding: 10px;
    min-width: 120px;
    text-align: center;
    background-color: #0a4570;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.btn:hover {
    background-color: #063b5a;
}

.Examtitle {
    text-align: center;
    color: #0a4570;
    margin-bottom: 20px;
}

/* Modal Styling */

.image-preview-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.image-preview-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center content vertically */
}

.image-preview {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.image-card {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
}

.image-preview-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.image-preview-buttons button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.image-preview-buttons button.confirm {
    background-color: #0a4570;
    color: #fff;
}

.image-preview-buttons button.cancel {
    background-color: #ccc;
    color: #333;
}

@media (max-width: 768px) {
    .edit-exam{
        padding: 15px;
        margin-top: 50px; /* Adjust as needed */
        margin-bottom: 20px; /* Adjust as needed */
        margin-left: 40px;
    }
    .forme-group-question, .option-row {
        flex-direction: column;
        align-items: flex-start;
    }
    .upload-button, .add-option {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
    }
    .forme-control {
        width: 100%;
        padding-right: 10px;
    }
    .form-group-buttons {
        flex-direction: column;
        align-items: center;
    }
    .btn {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
    .image-preview-content {
        max-width: 90%;
        max-height: 90%;
    }
}
