
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.home-container {
    min-height: 100vh; /* Full viewport height */
    background-image: url('Assets/Image\ 3.png');
    background-size: cover; /* Cover the entire container */
    background-position: top center; /* Start the image from the top and center it horizontally */
    background-repeat: no-repeat; /* Prevent tiling */
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  /* Ensure the navbar is positioned relative to this container */
}
/* General Navbar Styles */
.navbar {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%; 
    height: 50px; 
    z-index: 1000; /* Ensure it sits above other content */
    padding: 0 20px; 
    margin: 0; 
    box-sizing: border-box; 
    display: flex; 
    align-items: center; 
    border-bottom: 1px solid #ddd; /* Add a border for separation */
    background: linear-gradient(
        rgba(255, 255, 255, 0.9) 0%, /* High opacity at the top */
        rgba(255, 255, 255, 0.6) 50%, /* Medium opacity in the middle */
        rgba(255, 255, 255, 0.3) 75%, /* Lower opacity towards the bottom */
        rgba(255, 255, 255, 0) 100% /* Fully transparent at the bottom */
    ); 
    backdrop-filter: blur(7px); /* Initial blur effect */
    transition: transform 0.3s ease-in-out, backdrop-filter 0.3s ease; /* Smooth transition for hiding/showing and blur effect */
}

/* Hide navbar */
.navbar.hidden {
    transform: translateY(-100%); /* Move navbar up out of view */
}

/* Navbar menu styles for desktop */
.navbar-menu {
    display: flex;
    justify-content: center; 
    align-items: center; 
    list-style: none; 
    margin: 0; 
    padding: 0; 
    width: 100%; /* Make it span the entire width of the navbar */
}

/* Navbar menu links for desktop */
.navbar-menu a {
    text-decoration: none;
    color: #0a4570; /* Text color */
    font-size: 1.3em;
    cursor: pointer;
    margin-left: 30px; /* Adjust spacing between menu items */
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    transition: color 0.3s; /* Smooth color transition */
}

.navbar-menu a:hover {
    color: #000000; /* Color change on hover */
}

/* Toggle button styles */
.toggle-button {
    display: none; /* Hide the toggle button by default */
    background: none;
    border: none;
    color: #0a4570; /* Toggle button color */
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease; /* Smooth transition for shifting */
}

/* Media query for small screens */
@media (max-width: 768px) {
    .navbar-menu {
        
        display: flex; /* Show the menu by default on small screens */
        flex-direction: column;
        position: fixed; 
        top: 150px; /* Align the menu with the bottom of the navbar */
        left: 0; /* Align to the left side of the screen */
        width: 30%; /* Width of the navbar on mobile screens */
        height: calc(100% - 50px); /* Full height minus the navbar height */
       margin-top: 10px;
    
        transform: translateX(-100%); /* Start with the menu off-screen */
        transition: transform 0.3s ease-in-out; /* Smooth transition for sliding in/out */
        padding-top: 10px; /* Space above the first item */
        z-index: 999; /* Ensure it sits above other content */
    }

    .navbar-menu.show {
        transform: translateX(0); /* Slide the menu in from the left */
        border-bottom: #0a4570;

    }

    .navbar-menu li {
        margin-top: 25px; /* Reduced space between menu items */
        padding: 2px 10px; /* Space inside each item */
        display: flex;
        align-items: center; /* Center items vertically */
        border-bottom: none; /* Remove bottom border from each item */
    }

    .toggle-button {
        display: block; /* Show the toggle button on small screens */
        z-index: 1001; /* Ensure it's above the menu */
    }

    .navbar-menu a {
        text-decoration: none;
        color: #0a4570; /* Text color */
        font-size: 1.2em; /* Slightly smaller font size */
        cursor: pointer;
        margin-top: 10px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-weight: bold;
        transition: color 0.3s; /* Smooth color transition */
        flex-grow: 1; /* Allow the link to take up available space */
        display: flex;
        align-items: center; /* Center text and icon vertically */
    }

    .navbar-menu a:hover {
        color: #000000; /* Color change on hover */
    }

    .navbar-menu .icon {
        margin-right: 10px; /* Space between icon and text */
    }
}


/* Login Button Styles */
.login-button {
    background-color: transparent; 
    color: #0a4570; 
    border: 2px solid #0a4570; 
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 1.1em;
    cursor: pointer;
    margin-left: auto; /* Push the button to the right end */
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    border: 2px solid #0a4570; /* White border */
    text-decoration: none;
    border-radius: 8px;

    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* White glow effect */
}

.login-button:hover {
    background-color: #0a4570; 
    color: #ffffff;
}

.login-button:focus {
    outline: none; 
}



.header-content .heading{
font-family: monospace;
font-size: 50px;
color: #0a4570;
margin-bottom: 165px;
}
 .para{
    font-family: monospace;
    font-size: 26px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 900;
    color: #0a4570;
    margin-top: 7%;


    /* margin-bottom: 310px; */
    }

/* Header styles */
h3 {
    font-weight: 520;
    font-size: 1.6em;
    font-family: "Handlee", cursive;
    color: #1f6596;
    margin-top: 40px;
    margin-left: 60px;
}


.login-options {
    margin-top: 20px;
}

/* Button styles */
.registerbutton{
    display: inline-block;
    padding: 6px 70px;
    font-size: 1.9em;
    color:#0a4570; /* White text color */
    background: transparent; /* Transparent background */
    border: 2px solid #0a4570; /* White border */
    color: #f3f3f3; /* Black text color on hover */
    background-color: #0a4570;
    text-decoration: none;
    border-radius: 8px;
   margin-top: 340px;
   margin-left: 2px;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* White glow effect */
}

.registerbutton:hover {
    color: #f3f3f3; /* Black text color on hover */
     background-color: #063658;
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); */

}


/* Gallery section styles */
.gallery-section {
    padding: 50px 0;
    background-color: #f0f0f0a9; 
    text-align: center;
    /* background-image: url('pencil.png'); */
}

.gallery-heading {
    font-size: 30px; /* Adjusted size for visibility */
    font-family: Cursive; /* Cursive font */
    margin-bottom: 30px;
    color: #000000; /* Dark text color for contrast */
}

.gallery-container {
    display: flex;
    flex-wrap: wrap; /* Wrap items to the next line */
    justify-content: center; /* Center items horizontally */
    gap: 40px; /* Space between boxes */
    max-width: 1200px; /* Limit container width */
    margin: 0 auto; /* Center container horizontally */
    padding: 20px;
    box-shadow: rgb(0, 0, 0, 0.7); /* Padding around the container */
}

.gallery-item {
    flex: 1 1 calc(25% - 20px); /* 4 items per row with gaps */
    height: 200px; /* Fixed height for boxes */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    background-color: white; /* Transparent white background */
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Darker shadow on hover */
    transition: box-shadow 0.3s, transform 0.3s;
}

.gallery-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensure image covers the container */
    border-radius: 8px; /* Rounded corners for images */
}

.gallery-item:hover {
    transform: scale(1.05); /* Slightly scale up on hover */
}




/* Scrollbar styles for WebKit-based browsers */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: #0a4570; /* Blue color for the scrollbar */
    border-radius: 2px; /* Rounded corners for the scrollbar */
}

::-webkit-scrollbar-track {
 /* Light background for the track */
    border-radius: 6px; /* Rounded corners for the track */
}



/* About Section */
.about-section {
    background-color: #e5f4ff;
    padding: 40px 20px;
    text-align: center;
}

.about-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}



/* Apply the jumping effect to the images in the about section */
.about-image img {
    margin-top: 130px;
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin-left: 7px;
    margin-right: 50px;
    border-radius: 1.7%;
   
}

.about-text {
    flex: 1;
    text-align: left;
}

.about-text h2 {
    margin-left: 10px;
    font-size: 2.5em;
    color: #0a4570;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.about-text h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
    margin-left: 5px;
}

.about-text p {
    font-size: 1em;
    margin-left: 5px;
    line-height: 1.6;
    color: #666;
}

.homebtn-about {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #0a4570;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.homebtn-about:hover {
    background-color: #0a4570;
}

/* Why Choose Us Section */
.why-choose-us {
 /* background-image: url('pencil.png'); */
    padding: 40px 20px;
    text-align: center;
    background-color: #eaf6ff;
}

.why-choose-us-content {

    margin-top: 20px;
}

.highlighted-text {
    font-size: 1.5em; /* Increase font size */
    font-weight: bold; /* Make text bold */
    color: #0a4570; /* Distinct color */
 /* Light background color to highlight */
    padding: 20px; /* Increase padding */
    border-radius: 10px; /* Rounded corners */
    font-family: "Handlee", cursive;
    margin: 20px auto; /* Center the highlighted text */
    max-width: 80%; /* Limit width to avoid stretching */
    text-align: center; /* Center the text */
}
.why-choose-us-content .chooseus{
    color: #666;
    margin-left: 200px;
    margin-right: 200px;
    display: center;
}

/* Cards Container */
.cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 30px;
}

.card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
    padding: 20px;
    
}

.card-content h3 {
    font-size: 1.8em;
    margin-left: 10px;
    color: #0a4570;
}
 
/* Media queries for responsiveness */
@media (max-width: 768px) {

    .gallery-item {
        flex: 1 1 calc(50% - 20px); /* 2 items per row on smaller screens */
    }

    .why-choose-us-content .chooseus {
        font-size: 0.9em; /* Adjusted font size */
        padding: 5px 15px; /* Adjusted padding */
        text-align: center; /* Center text horizontally */
        margin: 20px auto; /* Center content with vertical spacing */
        max-width: 90%; /* Limit width to avoid stretching */
        line-height: 1.6; /* Improve readability */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }

    .about-content {
        flex-direction: column;
    }

    .about-image img {
        width: 100%;
        max-width: 300px; /* Adjusted max width */
        height: auto; /* Ensure images maintain their aspect ratio */
    }
}

@media (max-width: 480px) {
    .gallery-item {
        flex: 1 1 100%; /* 1 item per row on very small screens */
    }

    /* .header-content h1 {
        font-size: 2em; 
    } */

    .why-choose-us-content .chooseus {
        font-size: 0.9em; /* Adjusted font size */
        padding: 5px 15px; /* Adjusted padding */
        text-align: center; /* Center text horizontally */
        margin: 20px auto; /* Center content with vertical spacing */
        max-width: 90%; /* Limit width to avoid stretching */
        line-height: 1.6; /* Improve readability */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }

    .header-content p {
        font-size: 1em; /* Adjusted font size */
    }

    .about-image img {
        width: 100%;
        height: auto; /* Ensure images maintain their aspect ratio */
    }
}

@media (max-width: 768px) {
    .highlighted-text {
        font-size: 1.2em; /* Adjusted font size */
        padding: 15px; /* Adjusted padding */
        max-width: 90%; /* Increased max-width */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }

    .chooseus {
        font-size: 0.9em; /* Adjusted font size */
        padding: 5px 15px; /* Adjusted padding */
        text-align: center; /* Center text horizontally */
        margin: 20px auto; /* Center content with vertical spacing */
        max-width: 90%; /* Limit width to avoid stretching */
        line-height: 1.6; /* Improve readability */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }

    .cards-container {
        flex-direction: column; /* Stack cards vertically */
        align-items: center;
    }

    .card {
        width: 100%; /* Full width for cards on medium screens */
        max-width: 400px; /* Limited max-width */
    }

    .about-image img {
        width: 100%;
        max-width: 300px; /* Adjusted max width */
        height: auto; /* Ensure images maintain their aspect ratio */
    }
}

@media (max-width: 480px) {
    .highlighted-text {
        font-size: 1em; /* Adjusted font size */
        padding: 10px; /* Adjusted padding */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }

    .chooseus {
        font-size: 0.9em; /* Adjusted font size */
        padding: 5px 15px; /* Adjusted padding */
        text-align: center; /* Center text horizontally */
        margin: 20px auto; /* Center content with vertical spacing */
        max-width: 90%; /* Limit width to avoid stretching */
        line-height: 1.6; /* Improve readability */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }

    .about-image img {
        width: 100%;
        height: auto; /* Ensure images maintain their aspect ratio */
    }

    .card {
        width: 100%; /* Full width for cards on small screens */
        max-width: 300px; /* Limited max-width */
    }
}
