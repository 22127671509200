/* Complete CSS for Login Page */

.wrapper-login {
  margin-top: 5rem;
  max-width: 400px;
  margin: 5% auto; /* Center horizontally */
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgb(18, 91, 133);
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .wrapper-login {
    max-width: 330px; /* Adjust max-width for smaller screens */
  }
}

.login-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #0a4570;
  text-align: center;
}

.input-field {
  position: relative;
  margin-bottom: 1.5rem;

}

.input-field input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 30px;
}

.input-field input:focus {
  outline: none;
  border-color: #0a4570;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
  animation: input-focus 0.3s;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
}

.show-password-login {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  font-size: 18px;
}

@keyframes input-focus {
  0% {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
  }
}

.submit-login-button {
  width: 100%;
  padding: 10px;
  background-color: #0a4570;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.submit-login-button:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
}

.submit-login-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-login {
  font-size: 0.8rem;
  color: #e31102;
}

.remember-forgot-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.remember-forgot-login label {
  display: flex;
  align-items: center;
}

.remember-forgot-login label input[type="checkbox"] {
  margin-right: 5px;
}

.remember-forgot-login button {
  font-size: 0.9rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #0a4570;
  text-decoration: underline;
}

.remember-forgot-login button:focus {
  outline: none;
}
.backtologinbutton {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust this value to set the desired left margin */
  margin-bottom: 10px;
  font-size: 16px;
  color: #0a4570; /* Adjust color as needed */
  cursor: pointer;
  text-decoration: none;
}

.backtologinbutton i {
  margin-right: 8px; /* Space between icon and text */
}

.forget-password-login {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.forget-password-login a {
  color: #0a4570;
  text-decoration: none;
  border-bottom: 1px solid #0a4570;
  margin-left: 5px;
}

.forget-password-login a:hover {
  text-decoration: underline;
}

.forget-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #0a4570;
  text-align: center;
}

.forget-email {
  position: relative;
  margin-bottom: 1.5rem;
}

.forget-email input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 30px;
}

.forget-email input:focus {
  outline: none;
  border-color: #0a4570;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.submit-forget-button {
  background-color: #0a4570;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
}

.submit-forget-button:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
}

.submit-forget-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: auto;
}



@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alert-message-login {
  color: #007bff;
  text-align: center;
  margin-top: 10px;
}

/* Responsive Media Queries */

@media (max-width: 768px) {
  .login-title,
  .forget-title {
    font-size: 1.5rem;
  }

  .remember-forgot-login {
    flex-direction: column;
  }

  .remember-forgot-login button {
    margin-top: 10px;
  }

  .submit-login-button,
  .submit-forget-button {
    width: 100%;
  }

  .input-icon {
    position: absolute;
    top: 40%;
    left: 10px;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
  }
}
