/* General Header Styles */
*
{
  box-sizing: border-box;
  font-family: "Georgia Serif";
}
body {
  background-image:url('../src/Assets/pencil.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 100vh; 
  margin: 0;
  /* overflow: hidden; */
} 
.header {
  background-color: #0a4570;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  position: relative;
  z-index: 100;
}

.logo-header {
  display: flex;
  align-items: center;
}

.logo-header img {
  height: 50px;
  width: auto;
}

.nav-bar {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-list li {
  margin: 0 15px;
}

.nav-button, .header-about {
  background: none;
  border: none;
  color: #fdfbfb;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 0;
}

.nav-button:hover, .header-about:hover {
  text-decoration: underline;
}

/* Authentication Buttons */
.auth {
  display: flex;
  align-items: center;
  margin-left: auto; /* Ensure it aligns near the toggle */
}

.loginn-button {
  background-color: #053a60;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 19px;
  margin-left: 10px; /* Consistent small margin near the toggle */
}

.loginn-button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Sidebar Toggle */
.sidebar-toggle {

  display: none;
  align-items: center;
  cursor: pointer;
  margin-left: auto; /* Position it at the end of the header */
}

.sidebar-toggle .menu-button {
  font-size: 24px;
  background: none;
  border: none;
  color: #fdfbfb;
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 150px;
  margin-top: 2px;
  max-width: 250px;
  height: 100%;
  background-color: #133f6b;
  color: white;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  padding-top: 60px; /* Slightly downward */
}

.sidebar.show {
  transform: translateX(0);
  margin-top: 2px;
  width: 150px;
}

.sidebar-list {
  list-style: none;
  padding: 0;
  margin: 20px;

}

.sidebar-list li {
  padding: 10px 20px;

}

.sidebar-list li button {
  background: none;
  cursor: pointer;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
  text-align: left;
  width: 100%;
}

.sidebar-list li button:hover {
  color: #ddd;
}

.close-button {
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1280px) {
  .nav-bar {
    display: none; /* Hide the nav bar on small screens */
  }

  .auth {
    display: flex; /* Show auth buttons on mobile screens */
  }

  .sidebar-toggle {
    display: flex; /* Show the sidebar toggle button */
  }
  .loginn-button{
    margin-left: 590px; /* Fine-tune positioning */
  }

  .logo-header img {
    height: 40px;
  }

  .header {
    padding: 10px;
    justify-content: space-between; /* Ensure proper spacing */
  }
}

@media (max-width: 768px) {

  .auth {
    display: flex; /* Show auth buttons on mobile screens */
  }

  .sidebar-toggle {
    display: flex; /* Show the sidebar toggle button */
  }

  .logo-header img {
    height: 35px;
  }

  .loginn-button {
    font-size: 14px;
    padding: 6px 10px;
    margin-left: 195px; /* Fine-tune positioning */
  }

  .header {
    padding: 8px;
    justify-content: space-between; /* Ensure proper spacing */
  }
}

@media (max-width: 540px) and (min-height: 720px) {

  .auth {
    display: flex; /* Show auth buttons on mobile screens */
  }

  .sidebar-toggle {
    display: flex; /* Show the sidebar toggle button */
  }

  .logo-header img {
    height: 35px;
  }

  .loginn-button {
    font-size: 14px;
    padding: 6px 10px;
    margin-left: 195px; /* Fine-tune positioning */
  }
  .header {
    padding: 8px;
    justify-content: space-between; /* Ensure proper spacing */
 
  }
}


@media (max-width: 820px) {
  .loginn-button{
    margin-left: 530px;
  }
  .sidebar.show{
    width: 200px;
  }
}
@media (max-width: 1024px) {
  .loginn-button{
    margin-left: 530px;
  }
  .sidebar.show{
    width: 230px;
  }
}
@media (max-height: 1024px) {
  .loginn-button{
    margin-left: 600px;
  }
  .sidebar.show{
    width: 190px;
  }
}
@media (max-width: 480px) {
  .logo-header img {
    height: 30px;
  }

  .sidebar-list li button {
    font-size: 12px;
  }

  .loginn-button {
    font-size: 12px;
    padding: 4px 8px;
    margin-left: 180px; /* Fine-tune positioning */
  }
  .sidebar.show{
    width:150px;
  }

  .header {
    padding: 8px;
    justify-content: space-between; /* Ensure proper spacing */
  }

  .sidebar-toggle {
    margin-left: auto;
    margin-right: 10px;
  }
  
}
