/* General Styles */
.pricing-container {
    padding: 40px;
    background-color: #dbecf9;
    text-align: center;
}

.pricing-title {
    color: #0a4570;
    font-size: 40px;
    margin-bottom: 20px;
}

/* Pricing Boxes */
.pricing-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 40px;
}

.addphone {
    color: #0a4570;
    margin-left: 10px;
    margin-bottom: 5px;


}
.addphone strong{
text-decoration: underline;
}

.addphone .fa-phone {
    margin-right: 1px; /* Space between icon and text */
    font-size: 1.2em; /* Adjust the icon size */
}

.pricing-box {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.pricing-box:hover {
    transform: translateY(-5px);
}

.pricing-box h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #0a4570;
}

.price {
    font-size: 1.5em;
    color: #003c71;
    margin-top: 15px;
    margin-bottom: 10px;
}

.features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.features li {
    margin: 10px 0;
    color: #000000;
}

.addons-title {
    font-size: 2em;
    margin-bottom: 30px;
    color: #0a4570;
}

.addons-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 40px;
}

.addons-box {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.addons-box:hover{
    transform: translateY(-5px);
}
.addons-inner-text {
    font-size: 1.5em;
    color: #003c71;
    margin-bottom: 10px;
}

.contact-button {
    padding: 10px 50px;
    background-color: #0a4570;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.contact-button:hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (min-width: 768px) {
    .pricing-box {
        width: 45%;
    }

    .addons-box {
        width: 45%;
    }
   
}

@media (min-width: 1024px) {
    .pricing-box {
        width: 22%;
    }

    .addons-box {
        width: 30%;
    }
   
}
