/* General Footer Styles */
.footer {
  background-color: #0a4570; /* Dark blue background */
  color: #ffffff; /* White text */
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
  animation: fadeIn 1s ease-in;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

/* Align sections properly */
.footer-section {
  flex: 1;
  min-width: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.footer-info strong {
  margin-left: 10px;
}

/* Move "Get In Touch" section further down */
.footer-info {
  margin-top: 26px; /* Add margin to move it down */
}

/* Heading Styling */
.footer-heading {
  color: #ffffff; /* White for headings */
  margin-bottom: 15px; /* Gap between heading and content */
  font-size: 1.5rem;
  margin-left: 1px;
  border-bottom: 2px solid #a0c4ff; /* Light blue underline for emphasis */
  padding-bottom: 5px; /* Padding for heading underline */
}
.emailfooter {
  color: #0386e4;
}

/* Links Styling */
/* Footer Links Styling */
.footer-links-columns {
  display: flex;
  justify-content: space-around;
  margin-left: 0; /* Reset margin for mobile */
}

.footer-links-columns .column {
  flex: 1;
  margin: 0 20px; /* Add margin between columns */
}

.footer-links-columns .column:last-child {
  margin-right: 20px; /* Ensure last column has margin on the right */
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links ul li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.footer-links ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.footer-links ul li a:hover {
  color: #a0c4ff;
}

.footer-links i {
  margin-right: 10px;
}

/* Newsletter Form Styles */
.newsletter-form {
  display: flex;
  flex-direction: column;
  margin-left: 0; /* Reset margin for mobile */
}

.newsletter-form label {
  margin-top: 10px;
  color: #ffffff;
  font-weight: bold;
}

.newsletter-form input {
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ccddee; /* Light blue border */
  border-radius: 6px;
  font-size: 1rem;
}

.newsletter-form button {
  margin-top: 15px;
  padding: 12px 24px;
  background-color: #003c71; /* Slightly darker blue */
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.newsletter-form button:hover {
  background-color: #002b50; /* Even darker blue on hover */
}

/* Footer Bottom Styles */
.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
  color: #cccccc;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-links-columns {
    flex-direction: row;
    margin-right: 80px; /* Reset margin for mobile */
  }

  .footer-links-columns .column {
    margin-bottom: 20px; /* Add space between columns */
  }
  .footer-links ul li a{
    margin-left: 10px;
  }

  .footer-links-columns .column:last-child {
    margin-right: 5px; /* Reset margin for last column */
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .newsletter-form{
    margin-right: 80px;
  }



}

/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
