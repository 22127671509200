.container-quizz {
  padding: 20px;
  margin-left: 5%;
  margin-right: 2%;
  background-color: #fff;
  width: 90%;
  
}

.cardd {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cardd-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.back-button {
  color: #0a4570;
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
}

.card-bodyy {
  padding: 20px;
  margin-top: 0px;
}

.quizdetailheading {
  color: #0a4570;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.statusheading {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
}

.statusheading.active {
  color: green;
}

.statusheading.inactive {
  color: red;
}

.QuizDes {
  max-height: 350px;
  overflow-y: auto;
}

.QuizMcq, .QuizDes {
  color: #0a4570;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.card .card-header.Quizicon {
  display: flex;
  justify-content: space-between;
}

.card .cardd-header .edit-btn,
.card .cardd-header .delete-btn {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;
}

.edit-btn {
  color: #0a4570;
}

.delete-btn {
  color: red;
}

.card-bodyy .question-mcq,
.card-bodyy .question-des {
  font-size: 1.25rem;
  margin-bottom: 10px;
}


/* @media (max-width: 1200px) {
  .container-quiz {
    margin-left: 1%;
    padding: 15px;
  }
} */

.add-question{
  background-color: #0A4570;
  color: white;
  border: #ddd1d1;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  right: 200px;
  top: 215px;

}


@media (max-width: 992px) {
  .container-quiz {
    margin-left: 5%;
    padding: 10px;
  }
  
  .quizdetailheading {
    font-size: 1.25rem;
  }
  
  .QuizMcq, .QuizDes {
    font-size: 1rem;
  }

  .card-bodyy .question-mcq,
  .card-bodyy .question-des {
    font-size: 1rem;
  }
  
}

@media (max-width: 768px) {
  .container-quiz {
    margin-left:10%;
    padding: 10px;
  }
  .cardd-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .back-button {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .cardd-header .edit-btn,
  .cardd-header .delete-btn {
    font-size: 0.875rem;
    margin-left: 0;
    margin-right: 10px;
  }

  .QuizMcq, .QuizDes {
    font-size: 0.875rem;
  }

  .card-bodyy .question-mcq,
  .card-bodyy .question-des {
    font-size: 0.875rem;
  }
  .card .cardd-header.Quizicon{
    flex-direction: row;
  }
}

@media (max-width: 576px) {
  .card-body {
    padding: 10px;
  }
  
  .quizdetailheading {
    font-size: 1rem;
  }
  
  .QuizMcq, .QuizDes {
    font-size: 0.75rem;
  }

  .card-bodyy .question-mcq,
  .card-bodyy .question-des {
    font-size: 0.75rem;
  }
}
.card-bodyy {
  padding: 20px;
}

.question-mcq {
  margin-bottom: 10px;
}

.question-content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.text-content {
  flex: 1;
  margin-top: 20px;
  margin-left: 10px;
}

.image-container {
  max-width: 100px;
  margin-right: 10px;
}

.question-image  {
  max-width: 200px;
  max-height: 300px;
  display: left;


  height: auto;
}

.options-container {
  margin-top: 30px;
  font-size: 20px;

}


.options-container ol {

  padding-left: 20px; /* Adjust padding as needed */
  list-style: disc;
  margin-top: 30px;

}

.option-row {
  margin-bottom: 10px; /* Adjust spacing between options */
}


.option-list {
 /* Ensures ordered list numbers are visible */
  padding-left: 20px; /* Adds some padding to the left for visibility */
}

.option-item {
  margin-bottom: 10px; /* Adds some space between list items */
}

.option-item span {
  font-size: 16px; /* Adjust font size if necessary */
}

.li .option-row {
  display: left;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
}
.correct-answer-image{
    max-width: 200px;
    max-height: 300px;
    margin-top: 20px;
    display: left;
  
}
.option-row .image-container {
  max-width: 50px; /* Adjust as needed */
  margin-right: 10px;
}

.option-image {
  display: left;
  max-width: 200px;
  max-height: 300px;

}